import { Box, List, ListItem } from "@chakra-ui/react"
import FacetCount from "@components/Search/Filters/FacetCount"
import { HierarchicalMenuItem } from "instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu"

interface MenuListProps {
  items: HierarchicalMenuItem[]
  refine: (value: string) => void
  hierarchical?: boolean
}

const HierarchicalMenuList = ({
  items,
  refine,
  hierarchical,
}: MenuListProps) => {
  return (
    <List
      spacing={2}
      ms={hierarchical ? 4 : 0}
      mt={2}
    >
      {items.map((item) => (
        <ListItem key={item.value}>
          <Box
            as={"span"}
            key={item.value}
            fontWeight={item.isRefined ? "bold" : "normal"}
            onClick={(event) => {
              event.preventDefault()
              refine(item.value)
            }}
            cursor={"pointer"}
          >
            {item.label}
          </Box>
          <FacetCount count={item.count} />
          {item.data && (
            <HierarchicalMenuList
              items={item.data}
              refine={refine}
              hierarchical
            />
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default HierarchicalMenuList
