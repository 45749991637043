import HierarchicalMenuList from "@components/Search/HierarchicalMenuList"
import { useHierarchicalMenu } from "react-instantsearch-core"

export const menuAttributes = [
  "category.lvl0.en_AE",
  "category.lvl1.en_AE",
  "category.lvl2.en_AE",
]

const HierarchicalMenu = () => {
  const { items, refine } = useHierarchicalMenu({
    attributes: menuAttributes,
    sortBy: ["name:asc"],
    limit: 100,
  })

  return (
    <HierarchicalMenuList
      items={items}
      refine={refine}
    />
  )
}

export default HierarchicalMenu
