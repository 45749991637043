import { Stack } from "@chakra-ui/react"
import Banners from "@components/Banners/Banners"
import HomeCategories from "@components/Home/HomeCategories"
import HomeLayout from "@components/Layouts/HomeLayout"
import SpecialProducts from "@components/Product/SpecialProducts"
import { home_promos } from "@data/banners"
import { fetcher, getCurrentDomain } from "@utils/common"
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from "next"
import useSWR, { SWRConfig } from "swr"

const Home = ({
  fallback,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { data: latest } = useSWR("/api/products/latest", fetcher, {
    fallbackData: fallback?.["/api/products/latest"],
    revalidateOnFocus: false,
  })
  const { data: popular } = useSWR("/api/products/popular", fetcher, {
    fallbackData: fallback?.["/api/products/popular"],
    revalidateOnFocus: false,
  })
  const { data: children } = useSWR("/api/products/children", fetcher, {
    fallbackData: fallback?.["/api/products/children"],
    revalidateOnFocus: false,
  })
  const { data: topRated } = useSWR("/api/products/top-rated", fetcher, {
    fallbackData: fallback?.["/api/products/top-rated"],
    revalidateOnFocus: false,
  })
  const { data: categories } = useSWR("/api/products/categories", fetcher, {
    fallbackData: fallback?.["/api/products/categories"],
    revalidateOnFocus: false,
  })

  return (
    <HomeLayout>
      <Banners
        banners={home_promos}
        promosLoading={false}
        promoType={"home"}
      />
      <SWRConfig value={{ fallback, revalidateOnFocus: false }}>
        <Stack
          spacing={8}
          direction={"column"}
        >
          {latest?.hits?.length > 0 && (
            <SpecialProducts
              results={latest}
              title={"New Releases"}
              style={"grid"}
            />
          )}

          {popular?.hits?.length > 0 && (
            <SpecialProducts
              results={popular}
              title={"Popular"}
              style={"grid"}
              shipsTargetHour={15}
            />
          )}

          {children?.hits?.length > 0 && (
            <SpecialProducts
              results={children}
              title={"Children"}
              style={"grid"}
            />
          )}

          {topRated?.hits?.length > 0 && (
            <SpecialProducts
              results={topRated}
              title={"Top Rated"}
              style={"grid"}
            />
          )}

          <HomeCategories categories={categories} />
        </Stack>
      </SWRConfig>
    </HomeLayout>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const domain = getCurrentDomain()
  const { res } = context
  res.setHeader(
    "Cache-Control",
    "public, s-maxage=3600, stale-while-revalidate"
  )

  const [latest, popular, children, topRated, categories] =
    await Promise.allSettled([
      fetcher(`${domain}/api/products/latest`),
      fetcher(`${domain}/api/products/popular`),
      fetcher(`${domain}/api/products/children`),
      fetcher(`${domain}/api/products/top-rated`),
      fetcher(`${domain}/api/products/categories`),
    ])

  return {
    props: {
      fallback: {
        ["/api/products/latest"]:
          latest.status === "fulfilled" ? latest.value : null,
        ["/api/products/popular"]:
          popular.status === "fulfilled" ? popular.value : null,
        ["/api/products/children"]:
          children.status === "fulfilled" ? children.value : null,
        ["/api/products/top-rated"]:
          topRated.status === "fulfilled" ? topRated.value : null,
        ["/api/products/categories"]:
          categories.status === "fulfilled" ? categories.value : null,
      },
    },
  }
}
