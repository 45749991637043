import {
  AutocompleteApi,
  AutocompleteState,
  InternalAutocompleteSource,
} from "@algolia/autocomplete-core"
import {
  Box,
  Card,
  Flex,
  HStack,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react"
import Ratings from "@components/Product/Ratings"
import { blurDataURLFromThumbHash } from "@utils/image"
import { Hit } from "instantsearch.js"
import {
  getHighlightedParts,
  getPropertyByPath,
} from "instantsearch.js/es/lib/utils"
import Image from "next/image"
import { BaseSyntheticEvent, KeyboardEvent, MouseEvent } from "react"
import { Highlight } from "react-instantsearch"

type AutocompleteProductItemProps = {
  hit: Hit
  source: InternalAutocompleteSource<Hit>
  autocomplete: AutocompleteApi<
    Hit,
    BaseSyntheticEvent,
    MouseEvent,
    KeyboardEvent
  >
  autocompleteState: AutocompleteState<Hit>
}

const AutocompleteProductItem = ({
  hit,
  source,
  autocomplete,
  autocompleteState,
}: AutocompleteProductItemProps) => {
  const listBgColor = useColorModeValue("white", "blackAlpha.300")

  const property =
    getPropertyByPath(hit._highlightResult, "name.en_AE" as string) || []
  const properties = Array.isArray(property) ? property : [property]

  const parts = properties.map((singleValue) =>
    getHighlightedParts(unescape(singleValue.value || ""))
  )

  const isHighlighted = (parts?.[0]?.[0]?.value !== "undefined") as boolean

  return (
    <ListItem
      {...autocomplete.getItemProps({
        item: hit,
        source,
      })}
      as={Card}
      _selected={{
        bgColor: "gray.100",
      }}
      bgColor={listBgColor}
      onClick={() => {
        const url =
          source.getItemUrl({
            item: hit,
            state: autocompleteState,
          }) || ""
        autocomplete.setIsOpen(false)
        autocomplete.navigator.navigate({
          itemUrl: url,
          item: hit,
          state: autocompleteState,
        })
      }}
      cursor={"pointer"}
      mx={4}
      my={2}
      p={2}
    >
      <HStack alignItems={"center"}>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
        >
          {hit.image?.length > 0 ? (
            <Image
              alt={hit.name.en_AE[0]}
              src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}${hit.image?.[0].contentUrl}?class=mthumb`}
              loader={({ src }) => src}
              unoptimized
              placeholder={"blur"}
              blurDataURL={blurDataURLFromThumbHash(
                hit.image?.[0].exifData.value
              )}
              width={hit.image?.[0].width?.value || 800}
              height={hit.image?.[0].height?.value || 1200}
              style={{
                minWidth: "48px",
                maxWidth: "48px",
              }}
            />
          ) : (
            <Image
              alt={hit.name.en_AE[0]}
              src={`${process.env.NEXT_PUBLIC_IMAGE_CDN}/b-img/${hit.gtin}.png?class=mthumb`}
              width={800}
              height={1200}
              placeholder={"blur"}
              blurDataURL={blurDataURLFromThumbHash(
                "JCoGDQDPmHd4enfoWIyGeMNwaQ2H"
              )}
              unoptimized
              style={{
                minWidth: "48px",
                maxWidth: "48px",
              }}
            />
          )}
        </Flex>

        <Box>
          <Box
            fontSize={"sm"}
            fontWeight={500}
          >
            {isHighlighted ? (
              <Highlight
                attribute={"name.en_AE"}
                hit={hit}
              />
            ) : (
              <Box as={"span"}>{hit.name.en_AE}</Box>
            )}
          </Box>

          {hit.author?.en_AE && (
            <Box fontSize={"xs"}>
              {"By "}
              <Box
                as={"span"}
                fontWeight={"500"}
                fontStyle={"italic"}
              >
                {isHighlighted ? (
                  <Highlight
                    attribute={"author.en_AE"}
                    hit={hit}
                    separator={", "}
                  />
                ) : (
                  <Box as={"span"}>{hit.author.en_AE.join(", ")}</Box>
                )}
              </Box>
            </Box>
          )}

          {hit.aggregateRating?.ratingValue > 0 && (
            <Ratings
              stars={hit.aggregateRating?.ratingValue}
              count={hit.aggregateRating?.ratingCount}
            />
          )}
        </Box>
      </HStack>
    </ListItem>
  )
}

export default AutocompleteProductItem
