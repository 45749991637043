import { Box } from "@chakra-ui/react"

const AutocompleteHeader = ({ title }: { title: string }) => {
  return (
    <Box
      fontWeight={500}
      fontSize={"sm"}
      mt={4}
      mb={2}
      mx={4}
      p={2}
      textTransform={"uppercase"}
    >
      {title}
    </Box>
  )
}

export default AutocompleteHeader
