import { Box, BoxProps } from "@chakra-ui/react"
import {
  TYPESENSE_SEARCH_PARAMETERS,
  TYPESENSE_SERVER_CONFIG,
} from "@common/Typesense"
import DefaultSections from "@components/Layouts/DefaultSections"
import Autocomplete from "@components/Search/Autocomplete/Autocomplete"
import VirtualHierarchicalMenu from "@components/Search/Filters/VirtualHierarchicalMenu"
import VirtualSearchBox from "@components/Search/Filters/VirtualSearchBox"
import { menuAttributes } from "@components/Search/HierarchicalMenu"
import { InstantSearch } from "react-instantsearch-core"
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"

const searchClient = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    ...TYPESENSE_SEARCH_PARAMETERS,
    per_page: 12,
  },
}).searchClient

const HomeLayout = ({ children, ...props }: BoxProps) => {
  return (
    <DefaultSections>
      <Box minH={16}>
        <InstantSearch
          indexName={"products"}
          searchClient={searchClient}
          routing={false}
        >
          <VirtualSearchBox />
          <VirtualHierarchicalMenu attributes={menuAttributes} />
          <Autocomplete />
        </InstantSearch>
      </Box>
      <Box
        mt={4}
        {...props}
      >
        {children}
      </Box>
    </DefaultSections>
  )
}

export default HomeLayout
