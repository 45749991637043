import { Box, Heading, HStack, Link, SimpleGrid } from "@chakra-ui/react"
import FacetCount from "@components/Search/Filters/FacetCount"
import NextLink from "next/link"

type HomeCategoriesProps = {
  categories: {
    value: string
    count: number
    isRefined: boolean
  }[]
}
const HomeCategories = ({ categories }: HomeCategoriesProps) => {
  return (
    <Box
      as={"section"}
      pb={2}
    >
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottom={"1px solid"}
        borderColor={"gray.200"}
        bgColor={"gray.100"}
        p={2.5}
      >
        <Heading
          color={"gray.900"}
          fontWeight={700}
          fontSize={"md"}
        >
          {"Categories"}
        </Heading>
      </HStack>
      <SimpleGrid
        spacing={2}
        columns={2}
        mt={2}
        aria-label={"Categories"}
      >
        {categories?.map((item) => (
          <Box
            key={item.value}
            borderBottom={"1px solid"}
            borderColor={"gray.200"}
            p={2}
            mx={0}
            my={1}
            aria-label={`${item.value} (${item.count})`}
          >
            <Link
              as={NextLink}
              href={`/c/${encodeURIComponent(item.value)}`}
              textDecoration={"none"}
              _hover={{
                textDecoration: "none",
              }}
            >
              <Box w={"full"}>
                <Box
                  as={"span"}
                  key={item.value}
                  fontWeight={item.isRefined ? "bold" : "normal"}
                  fontSize={"sm"}
                >
                  {item.value}
                </Box>
                <FacetCount
                  count={item.count}
                  bgColor={"yellow.200"}
                  color={"gray.900"}
                  fontWeight={400}
                />
              </Box>
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default HomeCategories
