import { Badge, BadgeProps } from "@chakra-ui/react"

interface FacetCountProps extends BadgeProps {
  count: number
}

const FacetCount = ({ count, ...rest }: FacetCountProps) => {
  return (
    <Badge
      ms={1}
      {...rest}
    >
      {Intl.NumberFormat("en-AE", {
        notation: "compact",
        maximumFractionDigits: 0,
        useGrouping: true,
        compactDisplay: "short",
      }).format(count) + `${count > 999 ? "+" : ""}`}
    </Badge>
  )
}

export default FacetCount
