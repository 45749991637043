import {
  AutocompleteApi,
  AutocompleteState,
  InternalAutocompleteSource,
} from "@algolia/autocomplete-core"
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react"
import { Hit } from "instantsearch.js"
import { BaseSyntheticEvent, KeyboardEvent, MouseEvent } from "react"
import { MdArrowOutward, MdDelete, MdHistory } from "react-icons/md"

type AutocompleteRecentItemProps = {
  hit: Hit
  source: InternalAutocompleteSource<Hit>
  autocomplete: AutocompleteApi<
    Hit,
    BaseSyntheticEvent,
    MouseEvent,
    KeyboardEvent
  >
  autocompleteState: AutocompleteState<Hit>
}

const AutocompleteRecentItem = ({
  hit,
  source,
  autocomplete,
  autocompleteState,
}: AutocompleteRecentItemProps) => {
  const listBgColor = useColorModeValue("white", "blackAlpha.300")

  return (
    <ListItem
      {...autocomplete.getItemProps({
        item: hit,
        source,
      })}
      _selected={{
        bgColor: "gray.100",
      }}
      bgColor={listBgColor}
      onClick={() => {
        const url =
          source.getItemUrl({
            item: hit,
            state: autocompleteState,
          }) || ""
        autocomplete.setIsOpen(false)
        autocomplete.navigator.navigate({
          itemUrl: url,
          item: hit,
          state: autocompleteState,
        })
      }}
      cursor={"pointer"}
      mx={4}
      px={2}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <HStack>
          <Icon
            aria-label={"history"}
            as={MdHistory}
            color={"gray.500"}
          />
          <Box as={"span"}>{hit.label}</Box>
        </HStack>
        <HStack spacing={0}>
          <IconButton
            aria-label={"Remove This Search"}
            title={"Remove This Search"}
            icon={<MdDelete />}
            variant={"ghost"}
            color={"gray.500"}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              source.onRemove(hit.id)
            }}
            size={"md"}
          />
          <IconButton
            aria-label={`Fill query with ${hit.label}`}
            title={`Fill query with ${hit.label}`}
            icon={<MdArrowOutward />}
            variant={"ghost"}
            color={"gray.500"}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              source.onTapAhead(hit)
            }}
          />
        </HStack>
      </Flex>
    </ListItem>
  )
}

export default AutocompleteRecentItem
