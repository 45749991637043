import { ConfigurationOptions } from "typesense/lib/Typesense/Configuration"

export const TYPESENSE_SERVER_CONFIG: ConfigurationOptions = {
  apiKey: process.env.NEXT_PUBLIC_TYPESENSE_API_KEY || "xyz",
  nodes: [
    {
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL || "http",
      host: process.env.NEXT_PUBLIC_TYPESENSE_HOST_1 || "localhost",
      port: Number(process.env.NEXT_PUBLIC_TYPESENSE_PORT) || 8108,
    },
    {
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL || "http",
      host: process.env.NEXT_PUBLIC_TYPESENSE_HOST_2 || "localhost",
      port: Number(process.env.NEXT_PUBLIC_TYPESENSE_PORT) || 8108,
    },
    {
      protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL || "http",
      host: process.env.NEXT_PUBLIC_TYPESENSE_HOST_3 || "localhost",
      port: Number(process.env.NEXT_PUBLIC_TYPESENSE_PORT) || 8108,
    },
  ],
  numRetries: 3,
  connectionTimeoutSeconds: 5,
  cacheSearchResultsForSeconds: 30 * 60,
}

export const TYPESENSE_SEARCH_PARAMETERS = {
  query_by: "name,author,bookFormat,illustrator,description,gtin,isbn",
  sort_by:
    "_eval(inLanguage:=[English]):desc,offers.availability(missing_values: last):asc",
  drop_tokens_threshold: 100,
}
