import {
  AutocompleteOptionsWithMetadata,
  AutocompleteState,
  createAutocomplete,
} from "@algolia/autocomplete-core"
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches"
import {
  Box,
  Container,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  List,
  useColorModeValue,
} from "@chakra-ui/react"
import {
  TYPESENSE_SEARCH_PARAMETERS,
  TYPESENSE_SERVER_CONFIG,
} from "@common/Typesense"
import AutocompleteHeader from "@components/Search/Autocomplete/AutocompleteHeader"
import AutocompleteProductItem from "@components/Search/Autocomplete/AutocompleteProductItem"
import AutocompleteRecentItem from "@components/Search/Autocomplete/AutocompleteRecentItem"
import { Hit } from "instantsearch.js"
import NextLink from "next/link"
import {
  BaseSyntheticEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MdArrowBack, MdClear } from "react-icons/md"
import { useSearchBox } from "react-instantsearch-core"
import Typesense from "typesense"
import { SearchResponse } from "typesense/lib/Typesense/Documents"
// https://github.com/typesense/typesense-instantsearch-adapter/issues/88
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SearchResponseAdapter } from "typesense-instantsearch-adapter/lib/SearchResponseAdapter"

type AutocompleteItem = Hit

const Autocomplete = (
  props: Partial<AutocompleteOptionsWithMetadata<AutocompleteItem>>
) => {
  const { query } = useSearchBox()

  const typesenseClient = useMemo(
    () => new Typesense.Client(TYPESENSE_SERVER_CONFIG),
    []
  )

  const search_response_adapter = useCallback(
    (result: SearchResponse<object>) =>
      new SearchResponseAdapter(
        result,
        { params: {} },
        { geoLocationField: "_geoloc" }
      ),
    []
  )

  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: query || "",
    activeItemId: null,
    status: "idle",
  })

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: "search",
      limit: 5,
      transformSource({ source, onRemove, onTapAhead }) {
        return {
          ...source,
          getItemUrl({ item }) {
            return `/s?products[query]=${item.label}`
          },
          onRemove,
          onTapAhead,
        }
      },
    })

    return [recentSearches]
  }, [])

  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >({
        id: "autocomplete",
        placeholder: "Search",
        plugins,
        openOnFocus: true,
        onStateChange({ state }) {
          setAutocompleteState(state)
        },
        getSources({ query }) {
          return typesenseClient
            .collections("products")
            .documents()
            .search({
              q: query,
              ...TYPESENSE_SEARCH_PARAMETERS,
            })
            .then((results) => {
              return [
                {
                  sourceId: "products",
                  getItems() {
                    return search_response_adapter(results).adapt().hits
                  },
                  getItemUrl({ item }: { item: AutocompleteItem }) {
                    return `/p/${item.alternateName.en_AE[0]}/${item.id}`
                  },
                },
              ]
            })
            .catch((error) => {
              console.error(error)
              return []
            })
        },
        onSubmit({ state, event }) {
          event.preventDefault()
          window.location.assign(`/s?products[query]=${state.query}`)
        },
        ...props,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const { getEnvironmentProps } = autocomplete

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    })

    window.addEventListener("mousedown", onMouseDown)
    window.addEventListener("touchstart", onTouchStart)
    window.addEventListener("touchmove", onTouchMove)

    return () => {
      window.removeEventListener("mousedown", onMouseDown)
      window.removeEventListener("touchstart", onTouchStart)
      window.removeEventListener("touchmove", onTouchMove)
    }
  }, [getEnvironmentProps, autocompleteState.isOpen])

  useEffect(() => {
    if (autocompleteState.isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [autocompleteState.isOpen])

  return (
    <Box
      {...autocomplete.getRootProps({})}
      w={"full"}
      h={autocompleteState.isOpen ? "100vh" : "auto"}
      position={autocompleteState.isOpen ? "fixed" : "relative"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgColor={useColorModeValue("white", "black")}
      overflow={autocompleteState.isOpen ? "scroll" : "visible"}
      zIndex={autocompleteState.isOpen ? 100 : 0}
    >
      <Container
        maxW={"container.lg"}
        px={0}
      >
        <Box
          p={autocompleteState.isOpen ? 1 : 0}
          borderRadius={"lg"}
          zIndex={2}
          position={"sticky"}
          top={0}
          left={0}
          right={0}
          bgColor={useColorModeValue("white", "black")}
        >
          <form
            ref={formRef}
            {...autocomplete.getFormProps({ inputElement: inputRef.current })}
          >
            <InputGroup>
              <Input
                ref={inputRef}
                {...autocomplete.getInputProps({
                  inputElement: inputRef.current,
                })}
                value={autocompleteState.query}
                type={"text"}
                enterKeyHint={"search"}
              />
              {autocompleteState.isOpen && (
                <InputLeftElement>
                  <IconButton
                    aria-label={"back"}
                    title={"Back"}
                    icon={<MdArrowBack />}
                    variant={"ghost"}
                    _hover={{ bgColor: "transparent" }}
                    onClick={() => {
                      autocomplete.setIsOpen(false)
                    }}
                  />
                </InputLeftElement>
              )}
              <InputRightElement>
                {autocompleteState.query.length > 0 && (
                  <IconButton
                    aria-label={"clear"}
                    title={"Clear"}
                    type={"reset"}
                    icon={<MdClear />}
                    variant={"ghost"}
                    _hover={{ bgColor: "transparent" }}
                  />
                )}
              </InputRightElement>
            </InputGroup>
          </form>
        </Box>
        {!autocompleteState.isOpen && (
          <Flex justifyContent={"flex-end"}>
            <Link
              as={NextLink}
              href={"/s"}
              fontSize={"xs"}
              color={"blue.600"}
              textDecoration={"underline"}
              textDecorationStyle={"dotted"}
              textUnderlineOffset={"0.3em"}
              _hover={{
                textDecoration: "none",
              }}
            >
              {"Advanced Search"}
            </Link>
          </Flex>
        )}
      </Container>

      {autocompleteState.isOpen && (
        <Container maxW={"container.lg"}>
          <Box
            ref={panelRef}
            position={"relative"}
            {...autocomplete.getPanelProps({})}
          >
            {autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection

              switch (source.sourceId) {
                case "recentSearchesPlugin":
                  return (
                    <Box
                      as={"section"}
                      key={`source-${index}`}
                      position={"relative"}
                    >
                      <AutocompleteHeader title={"Recent Searches"} />
                      {items.length > 0 && (
                        <List {...autocomplete.getListProps()}>
                          {items.map((hit) => {
                            return (
                              <AutocompleteRecentItem
                                key={hit.id}
                                autocomplete={autocomplete}
                                autocompleteState={autocompleteState}
                                source={source}
                                hit={hit}
                              />
                            )
                          })}
                        </List>
                      )}
                    </Box>
                  )

                case "products":
                  return (
                    <Box
                      as={"section"}
                      key={`source-${index}`}
                      position={"relative"}
                    >
                      <AutocompleteHeader title={"Products"} />

                      <List {...autocomplete.getListProps()}>
                        {items.map((hit) => {
                          return (
                            <AutocompleteProductItem
                              key={hit.id}
                              autocomplete={autocomplete}
                              autocompleteState={autocompleteState}
                              source={source}
                              hit={hit}
                            />
                          )
                        })}
                      </List>
                    </Box>
                  )
              }
            })}
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default Autocomplete
