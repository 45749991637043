import {
  useHierarchicalMenu,
  UseHierarchicalMenuProps,
} from "react-instantsearch-core"

const VirtualHierarchicalMenu = (props: UseHierarchicalMenuProps) => {
  useHierarchicalMenu(props)

  return null
}

export default VirtualHierarchicalMenu
